<script>
import { mapGetters } from "vuex";

import treatmentService from "@/services/treatment";
import treatmentTaskService from "@/services/treatment-task";
import { TASKS_TYPES } from "@/services/constants";

import Spinner from "@/components/ui/Spinner";
import TaskCard from "@/components/ui/TaskCard";

export default {
  name: "TreatmentTasks",

  components: {
    TaskCard,
    Spinner,
  },

  data() {
    return {
      TASKS_TYPES,
      isLoading: false,
      searchFilter: "",
      treatmentId: this.$route.params.id,
      treatmentName: "",
      selectedTaskType: this.$route.params.type || "all",
      tasks: [],
    };
  },

  computed: {
    ...mapGetters(["isDoctor"]),
    filteredTasks() {
      return this.tasks.filter((task) => {
        const matchesTaskType =
          this.selectedTaskType === "all"
            ? true
            : task.type === this.selectedTaskType;

        const matchesSearch =
          this.searchFilter === "" ? true : this.includesQuery(task.name);

        return matchesTaskType && matchesSearch;
      });
    },
  },

  created() {
    this.getTreatment();
    this.getAllTasks();

    this.$bus.$on("task-update", (task) => {
      const index = this.tasks.findIndex((t) => t._id === task._id);
      if (index >= 0) {
        this.$set(this.tasks, index, task);
      } else {
        this.tasks.unshift(task);
      }
    });
  },

  methods: {
    includesQuery(string = "") {
      const searchFilter = this.searchFilter.toLocaleLowerCase();
      return string.toLocaleLowerCase().includes(searchFilter);
    },

    filterByType(task) {
      this.selectedTaskType = task;
    },

    getTreatment() {
      treatmentService.getById(this.treatmentId).then((treatment) => {
        this.treatmentName = treatment.name;
      });
    },

    getClassName(type) {
      const className = `${type}-color`;

      if (this.selectedTaskType === type) {
        return `${className} is-active`;
      } else {
        return className;
      }
    },

    getAllTasks() {
      this.isLoading = true;
      return Promise.all(
        Object.keys(TASKS_TYPES).map((type) =>
          treatmentTaskService
            .get({ treatmentID: this.treatmentId, taskType: type })
            .then((tasks) => {
              return tasks.map((task) => ({ ...task, type }));
            })
        )
      )
        .then((tasks) => {
          this.tasks = tasks.flat();
        })
        .finally(() => (this.isLoading = false));
    },

    deleteTask(task) {
      this.$confirm(`¿Desea eliminar el protocolo: ${task.name}?`, "Eliminar", {
        confirmButtonText: "Aceptar",
        cancelButtonText: "Cancelar",
        type: "warning",
      })
        .then(() => {
          treatmentTaskService.delete({
            taskType: task.type,
            taskID: task._id,
          });

          this.tasks = this.tasks.filter((t) => t._id !== task._id);
        })
        .catch(() => {});
    },
  },
};
</script>

<template lang="pug">
  div
    section.treatment-tasks(v-if="isLoading")
      spinner
    section.treatment-tasks(v-else)
      header.headline
        .headline__title
          h1
           | Tareas de {{ treatmentName | capitalize }}
           small(v-if="filteredTasks.length")  ({{ filteredTasks.length }})
          hr

        .headline__actions
          el-input(prefix-icon="el-icon-search" v-model="searchFilter" placeholder="Buscar" :clearable="true")

      article.row
        nav.sidebar
          a.sidebar__link(@click="filterByType('all')", :class="getClassName('all')")
            micon(name="apps")
            strong.sidebar__text Todos

          a.sidebar__link(v-for="type in TASKS_TYPES", @click="filterByType(type.type)", :class="getClassName(type.type)")
            micon(:name="type.icon")
            strong.sidebar__text {{ type.name }}

        .box.box--full.box--with-subnav
          .content
            task-card(v-if="selectedTaskType !== 'all' && !isDoctor" :taskType="selectedTaskType" isCreate)
            task-card(
              v-for="task in filteredTasks",
              :key="task._id",
              :task="task",
              :can-delete="!isDoctor"
              @onDelete="deleteTask(task)",
              type="treatment"
            )
      router-view
</template>

<style lang="scss" scoped>
.treatment-tasks {
  .title {
    margin: auto 5px;
  }

  .content {
    display: flex;
    flex-wrap: wrap;
    padding: 10px;
  }

  .vitals-color {
    .micon {
      fill: $vital;
    }
  }
  .checkins-color {
    .micon {
      fill: $checkin;
    }
  }
  .medications-color {
    .micon {
      fill: $medication;
    }
  }
  .appointments-color {
    .micon {
      fill: $appointment;
    }
  }
  .recommendations-color {
    .micon {
      fill: $recommendation;
    }
  }
  .exercises-color {
    .micon {
      fill: $exercise;
    }
  }
  .reports-color {
    .micon {
      fill: $report;
    }
  }
  .forms-color {
    .micon {
      fill: $checkin;
    }
  }

  .box.box--full.box--with-subnav {
    min-height: 440px;
  }
}
</style>
