<script>
import pagination from "@/mixins/pagination";
import taskDefaultList from "@/mixins/taskDefaultList";

export default {
  name: "FormsDefaultList",

  mixins: [pagination, taskDefaultList],

  data() {
    return {
      configType: "forms",
    };
  },
};
</script>

<template lang="pug">
section
  header.headline
    .headline__title
      h1
        | Formulario
        small(v-if="pagination.total")  ({{ pagination.total }})
      hr
    .headline__actions
      el-input(prefix-icon="el-icon-search" v-on:input="debounceInput" v-model="searchInput" placeholder="Buscar" :clearable="true")
      router-link.button.button--blue(:to="{name: 'create-task', params: { taskType: 'forms' }}" v-auth="'task-default.create'")
        micon(name="add_circle_outline").button__icon
        span.button__text Nuevo Formulario
  .box.box--with-subnav
    .box__content--stretch
      table
        thead
          tr
            th Nombre del Formulario
            th Descripcion
            th Acciones
        tbody
          spinner(v-if="isLoading && !tasks.length")
          tr(v-else, v-for="c in tasks", :key="c._id")
            td {{ c.name }}
            td {{ c.description }}
            td.actions
              .actions-container
                button.button.button--action(@click="edit(c._id)")
                  micon(name="edit")
                button.button.button--action(@click="remove(c)")
                  micon(name="delete_forever")
    pagination(
      :isLoading="isLoading"
      :limit="pagination.limit",
      :total="pagination.total",
      @pagination="setPagination"
    )
</template>
